import Vue from 'vue'
import VueI18n from 'vue-i18n'

import App from './App.vue'
import router from './router'
import store from './store'

import messages from './translations/index'

import '@/misc/register-service-worker'
// import '@/misc/handle-network-status'
// import '@/misc/handle-apple-install-prompt'
import 'pwacompat'

import '@/misc/scrollTo'

Vue.config.productionTip = false

Vue.use(VueI18n)

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'fr',
  messages // set locale messages
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
