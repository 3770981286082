<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
import { isNil } from 'lodash'
import NavBar from '@/components/NavBar';

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  components: { NavBar },
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.meta.lang === 'en') {
            this.$i18n.locale = 'en'
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/style.scss';
@import '@/theme/animations-layer.scss';

* {
  box-sizing: border-box;
}

body {
	margin: 0;
  font-weight: 300;
  font-family: $font;
  background-color: #fff;
}

img {
  display: block;
  height: auto;
  width: 100%;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
	margin: 0;
	padding: 0;
}
</style>
