<template>
  <div class="page-wrapper">

    <section class="hero" id="hero">
      <div class="left">
        <h1 class="medium-title" data-inview="fadeInUp" data-delay="400">Victor Duro</h1>
        <h2 class="big-title" data-inview="fadeInUp" data-delay="500">{{ $t("Prêt à partager ma vision stratégique, insuffler ma passion pour l’innovation.") }}</h2>
      </div>
      <div class="right" data-inview="revealLeft" data-delay="200">
        <img src="@/assets/img/victor-duro.jpg" class="victor" alt="Victor Duro">
      </div>
      <a href="#experiences" v-scroll-to:-100><img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow down" data-inview="fadeInDown" data-delay="600"></a>
      <div class="texture" data-inview="slideLeft" data-delay="300">
        <div class="dedication" v-if="to">
          <p class="label">{{ $t("À l’attention de :") }}</p>
          <p class="name">{{ to }}</p>
        </div>
        <img src="@/assets/img/texture-hero.jpg" alt="Texture">
      </div>
    </section>

    <section class="experiences" id="experiences">
      <div class="title-wrap">
        <div class="left" data-inview="fadeIn" data-delay="200">
          <h2 class="medium-title">{{ $t("Expériences") }}</h2>
          <h3 class="small-title">{{ $t("Un acteur de premier plan dans l’évolution du paysage numérique québécois de la dernière décennie.") }}</h3>
        </div>
        <div class="right" data-inview="fadeIn" data-delay="400">
          <p class="regular-text">{{ $t("Mon expérience m’a permis de développer, de mettre en marché et de faire rayonner plusieurs produits numériques novateurs et pertinents. Ayant orchestré d’importants virages visant à diversifier les revenus de Juste pour rire, du Cirque du Soleil, de Québecor et d’Alice et Smith Studios, je voue une réelle passion pour le marketing numérique et le commerce électronique.") }}</p>
        </div>
      </div>

      <div class="timeline">
        <div class="wrap">
          <p class="date start">2021</p>
          <p class="date end">2010</p>
          <div class="dot" :class="{ actif: position == 0 }" @click="goTo(0)"></div>
          <div class="dot" :class="{ actif: position == 1 }" @click="goTo(1)"></div>
          <div class="dot" :class="{ actif: position == 2 }" @click="goTo(2)"></div>
          <div class="dot" :class="{ actif: position == 3 }" @click="goTo(3)"></div>
          <div class="dot" :class="{ actif: position == 4 }" @click="goTo(4)"></div>
          <div class="dot" :class="{ actif: position == 5 }" @click="goTo(5)"></div>
          <div class="dot" :class="{ actif: position == 6 }" @click="goTo(6)"></div>
          <div class="dot" :class="{ actif: position == 7 }" @click="goTo(7)"></div>
        </div>
      </div>

      <flickity class="jobs-wrap main-carousel" ref="flickity" :options="flickityOptions">
        <div class="job carousel-cell">
          <div class="years">
            <p class="from">2020</p>
            <p v-if="currentLang === 'fr'" class="to">À&nbsp;ce&nbsp;jour</p>
            <p v-else class="to">to&nbsp;present&nbsp;day</p>
          </div>
          <div class="data">
            <p class="title">{{ $t("Cofondateur") }}<br><span>UPSMRT</span></p>
            <div class="regular-text">{{ $t("Élaborer les stratégies de monétisation de la première plateforme en ligne entièrement consacrée au mentorat dans le domaine des affaires.") }}</div>
            <img src="@/assets/img/upsmrt-logo.jpg" class="logo" alt="upsmrt logo">
          </div>
        </div>
        <div class="job carousel-cell">
          <div class="years">
            <p class="from">2019</p>
            <p v-if="currentLang === 'fr'" class="to">À&nbsp;ce&nbsp;jour</p>
            <p v-else class="to">to&nbsp;present&nbsp;day</p>
          </div>
          <div class="data">
            <p class="title">{{ $t("Partenaire") }}<br><span>Calfo</span></p>
            <div class="regular-text">{{ $t("Conseiller la présidence pour les investissements auprès des entreprises en démarrage œuvrant dans les domaines des médias, du marketing et du commerce électronique.") }}</div>
            <img src="@/assets/img/calfo-logo.jpg" class="logo" alt="Calfo logo">
          </div>
        </div>
        <div class="job carousel-cell">
          <div class="years">
            <p class="from">2018</p>
            <p v-if="currentLang === 'fr'" class="to">À&nbsp;ce&nbsp;jour</p>
            <p v-else class="to">to&nbsp;present&nbsp;day</p>
          </div>
          <div class="data">
            <p class="title">{{ $t("Partenaire") }}<br><span>Cobalt</span></p>
            <div class="regular-text">{{ $t("Conseiller la présidence dans l’orientation stratégique, le développement commercial et le financement de l’entreprise.") }}</div>
            <img src="@/assets/img/cobalt-logo.jpg" class="logo" alt="Cobalt logo">
          </div>
        </div>
        <div class="job carousel-cell">
          <div class="years">
            <p class="from">2017</p>
            <p class="to">2020</p>
          </div>
          <div class="data">
            <p class="title">{{ $t("Co-Fondateur & VP Commercial") }}<br><span>Alice and Smith Studios</span></p>
            <div class="regular-text">{{ $t("Élaborer des stratégies de monétisation et d’optimisation des revenus des jeux en collaboration avec les équipes de production.") }}</div>
            <img src="@/assets/img/alice-smith.jpg" class="logo" alt="Alice and Smith Studios logo">
          </div>
        </div>
        <div class="job carousel-cell">
          <div class="years">
            <p class="from">2016</p>
            <p class="to">2017</p>
          </div>
          <div class="data">
            <p class="title">{{ $t("Directeur de la transformation numérique") }}<br><span>Le Cirque du Soleil</span></p>
            <div class="regular-text">{{ $t("Élaborer et mettre en place un écosystème capable de répondre aux objectifs d’augmentation de 45 % du recrutement d’artistes et de 40 % de nouveaux numéros originaux.") }}</div>
            <img src="@/assets/img/cirque-du-soleil-logo.jpg" class="logo" alt="Le Cirque du Soleil logo">
          </div>
        </div>
        <div class="job carousel-cell">
          <div class="years">
            <p class="from">2013</p>
            <p class="to">2016</p>
          </div>
          <div class="data">
            <p class="title">{{ $t("Directeur marketing et commercial") }}<br><span>Groupe Juste pour rire</span></p>
            <div class="regular-text">{{ $t("Accompagner l’équipe de direction quant aux occasions de transformation numérique qui répondent aux objectifs de l’entreprise.") }}</div>
            <img src="@/assets/img/juste-pour-rire-logo.jpg" class="logo" alt="Groupe Juste pour rire logo">
          </div>
        </div>
        <div class="job carousel-cell">
          <div class="years">
            <p class="from">2013</p>
            <p class="to">2013</p>
          </div>
          <div class="data">
            <p class="title">{{ $t("Consultant : Stratège marketing et ecommerce") }}<br><span>La vie en Rose</span></p>
            <div class="regular-text">{{ $t("Développer la stratégie et les modèles d’affaires du commerce en ligne pour les bannières La Vie en Rose et Bikini Village.") }}</div>
            <img src="@/assets/img/la-vie-en-rose-logo.jpg" class="logo" alt="La vie en Rose logo">
          </div>
        </div>
        <div class="job carousel-cell">
          <div class="years">
            <p class="from">2010</p>
            <p class="to">2013</p>
          </div>
          <div class="data">
            <p class="title">{{ $t("Directeur de produits interactifs et numériques") }}<br><span>Groupe TVA</span></p>
            <div class="regular-text">{{ $t("Définir les stratégies de développement de produits, de services et de marques du Groupe TVA.") }}</div>
            <img src="@/assets/img/groupe-tva-logo.jpg" class="logo" alt="Groupe TVA logo">
          </div>
        </div>
      </flickity>
    </section>

    <section class="valeurs" id="valeurs">
      <div class="left">
        <h2 class="medium-title">{{ $t("Valeurs") }}</h2>
        <h3 class="small-title">{{ $t("Pendant que certains figent devant l’inconnu, j'y saute à pieds joints.") }}</h3>
        <img src="@/assets/img/signature.svg" class="signature" alt="Signature">
      </div>
      <div class="right">
        <div class="valeur" data-inview="revealLeft" data-delay="200">
          <h4 class="small-title">{{ $t("Imputabilité") }}</h4>
          <div class="regular-text">{{ $t("Les grandes décisions s’accompagnent de grandes responsabilités. Je me fais un point d’honneur de les respecter.") }}</div>
        </div>
        <div class="valeur" data-inview="revealLeft" data-delay="400">
          <h4 class="small-title">{{ $t("Créativité") }}</h4>
          <div class="regular-text">{{ $t("Les contraintes d’une entreprise font émerger des possibilités dont j’éprouve un grand plaisir à activer.") }}</div>
        </div>
        <div class="valeur" data-inview="revealLeft" data-delay="600">
          <h4 class="small-title">{{ $t("Curiosité") }}</h4>
          <div class="regular-text">{{ $t("La croissance cesse au moment où disparaît notre curiosité et c’est pourquoi je redouble d’efforts pour la stimuler.") }}</div>
        </div>
        <div class="valeur" data-inview="revealLeft" data-delay="800">
          <h4 class="small-title">{{ $t("Famille") }}</h4>
          <div class="regular-text">{{ $t("La famille est mon vecteur d’humanité, ce qui me permet de relativiser, de connecter et de réévaluer les priorités.") }}</div>
        </div>
      </div>
      <div data-inview="revealRight" data-delay="1000" class="texture">
        <img src="@/assets/img/texture-valeurs.jpg"  alt="Texture">
      </div>
    </section>

    <section class="aptitudes" id="aptitudes">
      <div class="left">
        <h2 class="medium-title">{{ $t("Aptitudes") }}</h2>
        <h3 class="small-title">{{ $t("Je m’efforce à bâtir sur mes forces pour collaborer et accroître celles des autres.") }}</h3>
        <p class="regular-text">{{ $t("Engagé et intègre, mes aptitudes me permettent de générer d’excellentes performances en édifiant mes relations.") }}</p>
      </div>
      <div class="right" data-inview="fadeInUp" data-delay="200">
        <div class="aptitude-title" :class="{'active': infoWrap === 1}" @click.prevent="showInfoWrap(1)">
          <h3 class="small-title">{{ $t("Stratégie numérique") }}</h3>
          <div class="plus-icon"></div>
        </div>
        <div class="aptitude-description" :class="{'active': infoWrap === 1}">
          <p class="regular-text">{{ $t("Capacité d’apporter une vision stratégique en menant une réflexion approfondie et créative.") }}</p>
        </div>

        <div class="aptitude-title" :class="{'active': infoWrap === 2}" @click.prevent="showInfoWrap(2)">
          <h3 class="small-title">{{ $t("Veille technologique") }}</h3>
          <div class="plus-icon"></div>
        </div>
        <div class="aptitude-description" :class="{'active': infoWrap === 2}">
          <p class="regular-text">{{ $t("Avant-gardiste, multidisciplinaire et à l’affût des grands courants locaux, nationaux et mondiaux.") }}</p>
        </div>

        <div class="aptitude-title" :class="{'active': infoWrap === 3}" @click.prevent="showInfoWrap(3)">
          <h3 class="small-title">{{ $t("Ressources humaines") }}</h3>
          <div class="plus-icon"></div>
        </div>
        <div class="aptitude-description" :class="{'active': infoWrap === 3}">
          <p class="regular-text">{{ $t("Excellent vulgarisateur ayant une grande capacité à motiver et à mobiliser le personnel.") }}</p>
        </div>

        <div class="aptitude-title" :class="{'active': infoWrap === 4}" @click.prevent="showInfoWrap(4)">
          <h3 class="small-title">{{ $t("Optimisation de processus") }}</h3>
          <div class="plus-icon"></div>
        </div>
        <div class="aptitude-description" :class="{'active': infoWrap === 4}" @click.prevent="showInfoWrap(1)">
          <p class="regular-text">{{ $t("Méthodique, ingénieux et possédant de fortes capacités organisationnelles.") }}</p>
        </div>

        <div class="aptitude-title" :class="{'active': infoWrap === 5}" @click.prevent="showInfoWrap(5)">
          <h3 class="small-title">{{ $t("Planification") }}</h3>
          <div class="plus-icon"></div>
        </div>
        <div class="aptitude-description" :class="{'active': infoWrap === 5}">
          <p class="regular-text">{{ $t("Excelle sous la pression avec un grand sens de l’imputabilité et de l’adaptabilité.") }}</p>
        </div>
      </div>
    </section>

    <section class="references-wrap" id="references">
        <div class="references">
          <img src="@/assets/img/quote.svg" class="quote" alt="Quote">

          <flickity v-if="currentLang === 'fr'" ref="flickity2" :options="flickityOptions2">
            <div class="temoignage carousel-cell">
              <p class="regular-text">Victor est l'une des meilleures têtes que j'ai eu la chance de rencontrer au niveau digital. En plus de posséder une excellente vision du numérique, il s'agit d'une personne de confiance et qui n'a qu'une seule parole. Chaque dossier avec Victor est un réel plaisir!</p>
              <div class="auteur">
                <img src="@/assets/img/dominic-gagnon.jpg" alt="Dominic Gagnon">
                <div class="regular-text">Dominic Gagnon<br><span>Co-Founder & CEO @ Connect&GO</span></div>
              </div>
            </div>
            <div class="temoignage carousel-cell">
              <p class="regular-text">Victor est à l'affût de tout ce qui se fait de nouveau en matière de produits interactifs. Il sait trouver des solutions peu coûteuses et qui ne demandent que très peu d'adaptation aux ressources humaines. Personnellement, j'apprécie Victor pour sa passion pour les nouvelles technologies qu'il partage avec qui veut l'entendre.</p>
              <div class="auteur">
                <img src="@/assets/img/sylvain-coutu.jpg" alt="Sylvain Coutu">
                <div class="regular-text">Sylvain Coutu<br><span>CTO @ Le Devoir</span></div>
              </div>
            </div>
            <div class="temoignage carousel-cell">
              <p class="regular-text">
                J’ai eu le plaisir de côtoyer Victor pendant deux ans, lors de mon passage au Groupe Juste pour rire. C’était avant
                son MBA et, déjà, il démontrait toutes les caractéristiques d’un entrepreneur aguerri, d’un idéateur. Il savait déjà
                créer « out of the box » au besoin. Encore aujourd’hui, j’ai avec Victor des discussions inspirantes qui, chaque fois,
                me font réfléchir et progresser dans mes propres projets.
              </p>
              <div class="auteur">
                <img src="@/assets/img/bruno.jpg" alt="Bruno">
                <div class="regular-text">Bruno Guglielminetti<br><span>Consultant / Producteur de balados @ MonCarnet.com</span></div>
              </div>
            </div>
          </flickity>

          <flickity v-else ref="flickity2" :options="flickityOptions2">
            <div class="temoignage carousel-cell">
              <p class="regular-text">
                Victor is very charismatic and a strong account manager.
                Passionate about his work, hard-working, and very curious, he seems to thrive when faced wit challenges.
                On top of being a customer-oriented person, he has empathy and understands the reality of the other teams, which creates an atmosphere prone to success & efficiency. It is a rare quality.
                Undoubtedly, all of the above makes Victor Duro an asset to any company.
              </p>
              <div class="auteur">
                <img src="@/assets/img/elia.jpg" alt="Elia">
                <div class="regular-text">Elia Vignau<br><span>Supply Coordinator @ Metro Trains Melbourne</span></div>
              </div>
            </div>
            <div class="temoignage carousel-cell">
              <p class="regular-text">
                I have worked with Victor on several projects and it always goes smoothly. He has great ideas and communicates directives clearly. His positive attitude and team spirit make it a pleasure to work with him. He is very knowledgeable and follows up on everything. If you have the chance to work with him, I strongly encourage you to do so.
              </p>
              <div class="auteur">
                <img src="@/assets/img/christina.jpg" alt="Christina">
                <div class="regular-text">Christina D.<br><span>Marketing Director @ Foodtastic</span></div>
              </div>
            </div>
            <div class="temoignage carousel-cell">
              <p class="regular-text">
                I had the pleasure of working and collaborating with Victor for over a year on a ground-breaking technology project
                that continues to lead positive change today. I was always impressed by Victor&#39;s energy and commitment to trying
                new things. In addition to just being an all-around genuine guy. As a team member, leader, or friend, Victor earns my
                highest recommendation.
              </p>
              <div class="auteur">
                <img src="@/assets/img/matthew.jpg" alt="Matthew">
                <div class="regular-text">Matthew Vaughan<br><span>Director, Aviation Security &amp; Cyber @ International Air Transport Association (IATA)</span></div>
              </div>
            </div>
            <div class="temoignage carousel-cell">
              <p class="regular-text">
                I had the privilege of working with Victor on the digital strategy for Cirque du Soleil’s casting talent attraction.
                Victor played a key role in helping us develop the new website, increase the outreach of our marketing messages, get
                better conversion of the casting website visitors to real applicants, and position the website among all the other
                digital and social platform communication channels. Victor&#39;s experience and strong strategic intuition were important
                assets in the success of this high-stack project. He took no time to grasp the nuances and navigate through the
                intricate complexity of the Cirque du Soleil’s corporate environment.
              </p>
              <div class="auteur">
                <img src="@/assets/img/pavel.jpg" alt="Pavel">
                <div class="regular-text">Pavel Kotov<br><span>Senior Casting Director @ Cirque du Soleil Entertainment Group</span></div>
              </div>
            </div>
          </flickity>

          <img src="@/assets/img/arrow-left.svg" @click="previous()" class="arrow-left" alt="arrow">
          <img src="@/assets/img/arrow-right.svg" @click="next()" class="arrow-right" alt="arrow">
        </div>
    </section>

    <section class="contact-wrap" id="contact">
      <div class="contact">
        <div class="left" data-inview="revealRight" data-delay="200">
          <img src="@/assets/img/contact.jpg" alt="Contact">
        </div>
        <div class="right" data-inview="fadeInUp" data-delay="400">
          <h3 class="medium-title">{{ $t("Parlons-nous") }}</h3>
          <p class="small-title">{{ $t("Je suis disponible pour répondre à vos questions.") }}</p>
          <div class="links">
            <a href="https://www.facebook.com/victor.duro" target="_blank"><img src="@/assets/img/facebook.svg" alt="Facebook"></a>
            <a href="https://www.linkedin.com/in/victorduro/" target="_blank"><img src="@/assets/img/linkedin.svg" alt="Linkedin"></a>
            <a href="mailto:vic.duro@gmail.com" class="regular-text">vic.duro@gmail.com</a>
          </div>
          <a href="#" class="cta" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/vic-duro/15min'});return false;">{{ $t("Planifions un appel") }}</a>
        </div>
      </div>
      <img src="@/assets/img/texture-contact.jpg" class="texture" alt="Texture">
    </section>

    <footer class="main-footer">
      <p class="regular-text">© Victor Duro - {{ $t("Tous droits réservés") }}</p>
      <img src="@/assets/img/texture-contact-2.jpg" class="texture" alt="Texture">
    </footer>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Flickity from 'vue-flickity'

export default {
  components: {
    Flickity
  },
  head() {
    return {
      title: {
        inner: this.$t("Un acteur de premier plan dans l’évolution du paysage numérique québécois de la dernière décennie.")
      },
      meta: [
        {
          name: 'description',
          content: this.$t("Mon expérience m’a permis de développer, de mettre en marché et de faire rayonner plusieurs produits numériques novateurs et pertinents. Ayant orchestré d’importants virages visant à diversifier les revenus de Juste pour rire, du Cirque du Soleil, de Québecor et d’Alice et Smith Studios, je voue une réelle passion pour le marketing numérique et le commerce électronique."),
          id: 'desc'
        },
        { name: 'application-name', content: `${this.appTitle}` },
        { name: 'twitter:title', content: this.$t("Un acteur de premier plan dans l’évolution du paysage numérique québécois de la dernière décennie.") },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t("Mon expérience m’a permis de développer, de mettre en marché et de faire rayonner plusieurs produits numériques novateurs et pertinents. Ayant orchestré d’importants virages visant à diversifier les revenus de Juste pour rire, du Cirque du Soleil, de Québecor et d’Alice et Smith Studios, je voue une réelle passion pour le marketing numérique et le commerce électronique.")
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t("Un acteur de premier plan dans l’évolution du paysage numérique québécois de la dernière décennie.") },
        { property: 'og:site_name', content: `${this.appTitle}` },
        {
          property: 'og:description',
          content: this.$t("Mon expérience m’a permis de développer, de mettre en marché et de faire rayonner plusieurs produits numériques novateurs et pertinents. Ayant orchestré d’importants virages visant à diversifier les revenus de Juste pour rire, du Cirque du Soleil, de Québecor et d’Alice et Smith Studios, je voue une réelle passion pour le marketing numérique et le commerce électronique.")
        },
        // Og Image
        {
          p: 'og:image',
          c: '/img/og.jpg'
        },
        {
          name: 'twitter:image',
          content: '/img/og.jpg'
        }
      ]
    }
  },
  data() {
    return {
      infoWrap: 0,
      flickityOptions: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        freeScroll: false,
        contain: true
      },
      flickityOptions2: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        freeScroll: false,
        contain: true
      },
      position: 0
    }
  },
  computed: {
    to() {
      if (!this.$route || !this.$route.query || !this.$route.query.to) {
        return null
      }

      return this.$route.query.to
    },
    currentLang() {
      return this.$i18n.locale
    },
    ...mapState('app', ['appTitle'])
  },
  methods: {
    showInfoWrap(value) {
      if(this.infoWrap === value) {
        this.infoWrap = 0
      } else {
        this.infoWrap = value
      }
    },

    goTo(pos) {
      this.$refs.flickity.select( pos )
    },

    next() {
      this.$refs.flickity2.next();
    },

    previous() {
      this.$refs.flickity2.previous();
    }
  },
  mounted() {
    if (window.location.href.includes('victor-duro.com')) {
      window.location.href = 'https://victorduro.ca/'
    }

    this.$refs.flickity.on( 'change', () => {
      this.position = this.$refs.flickity.selectedIndex()
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/style.scss';
@import '@/theme/flickity.scss';

.hero {
  width: 100%;
  padding: 0 12vw;
  margin-top: 104px;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  @media screen and (max-width: 1050px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 25px;
  }

  .arrow {
    width: 30px;
    position: absolute;
    left: 12vw;
    bottom: 0;
    cursor: pointer;

    @media screen and (max-width: 1050px) {
      left: 20px;
    }
  }

  .texture {
    width: 100%;
    max-width: 400px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 100;

    @media screen and (max-width: 1050px) {
      max-width: 340px;
    }

    @media screen and (max-width: 767px) {
      transform: scale(0.90) translate(-3px, 40px);
    }

    .dedication {
      position: absolute;
      top: 15px;
      left: 15px;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      background-color: $dark;

      p {
        color: #fff;
        font-size: 14px;
        opacity: 0.7;
        position: absolute;
        left: 20px;
        top: 25px;

        @media screen and (max-width: 1050px) {
          top: 20px;
        }
      }

      .name {
        opacity: 1;
        font-size: 18px;
        top: 45px;

        @media screen and (max-width: 1050px) {
          top: 38px;
        }
      }
    }
  }

  .left {
    width: 40%;
    position: relative;
    z-index: 100;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .big-title {
      width: 150%;

      @media screen and (max-width: 1050px) {
        width: 120%;
        margin-bottom: 35px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .medium-title {
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }

  .right {
    width: 60%;
    position: relative;
    z-index: 10;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.experiences {
  width: 100%;

  .title-wrap {
    width: 100%;
    padding: 4vw 12vw;
    margin-top: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    @media screen and (max-width: 1050px) {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 45px;
    }

    .left {
      width: 40%;

      @media screen and (max-width: 1050px) {
        width: 45%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
      }

      .medium-title {
        margin-bottom: 20px;
      }
    }

    .right {
      width: 40%;

      @media screen and (max-width: 1050px) {
        width: 45%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .timeline {
    width: 100%;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(0,0,0,0.1);
      position: absolute;
      left: 0;
      bottom: 15px;
      z-index: 10;
    }

    .wrap {
      width: 100%;
      padding: 0 12vw;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;

      @media screen and (max-width: 1050px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .date {
        width: 50%;
        font-size: 3.2vw;
        padding-bottom: 30px;

        @media screen and (max-width: 767px) {
          font-size: 16px;
          padding-bottom: 20px;
          padding-top: 20px;
          font-weight: 800;
        }

        &.start {
          text-align: left;
        }
        &.end {
          text-align: right;
        }
      }

      .dot {
        position: relative;
        z-index: 100;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #B7B7B7;
        cursor: pointer;

        &:hover {
          background-color: $dark;
        }

        &.actif {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          background-color: #fff;
          border: 1px solid $dark;
          position: relative;
          right: -10px;

          @media screen and (max-width: 767px) {
            right: 1px;
          }

          &:after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-color: $dark;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
    }
  }

  .jobs-wrap {
    width: 100%;
    margin-top: 4vw;
    padding-left: 12vw;

    @media screen and (max-width: 1050px) {
      padding-left: 20px;
    }

    .job {
      width: 530px;
      min-width: 530px;
      height: 300px;
      display: flex;
      margin-right: 30px;

      @media screen and (max-width: 767px) {
        width: 90vw;
        min-width: 90vw;
      }

      .years {
        width: 50px;
        background-color: $dark;
        position: relative;

        @media screen and (max-width: 767px) {
          width: 40px;
        }

        p {
          color: #fff;
          transform: translate(-50%, 0) rotate(-90deg);
          position: absolute;
          left: 50%;
          width: 100px;

          &.from {
            bottom: 60px;
            text-align: left;
          }

          &.to {
            top: 60px;
            text-align: right;
            white-space: nowrap;
          }
        }
      }

      .data {
        width: calc(100% - 50px);
        background-color: $light;
        padding: 30px;
        position: relative;

        @media screen and (max-width: 767px) {
          padding: 20px;
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.6;
          span {
            font-weight: 300;
          }
        }

        .regular-text {
          position: absolute;
          left: 30px;
          bottom: 30px;
          width: 55%;
          font-size: 14px;

          @media screen and (max-width: 767px) {
            left: 20px;
            bottom: 20px;
          }
        }

        .logo {
          position: absolute;
          right: 30px;
          bottom: 30px;
          width: 120px;

          @media screen and (max-width: 767px) {
            right: 20px;
            bottom: 20px;
            width: 80px;
          }
        }

      }
    }
  }
}

.valeurs {
  width: 100%;
  padding: 0 12vw;
  margin-top: 8vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1050px) {
    padding: 0 20px;
  }

  .texture {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    max-width: 400px;

    @media screen and (max-width: 1050px) {
      max-width: 340px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .left {
    width: 30%;
    position: relative;

    @media screen and (max-width: 767px) {
      width: 100%;


      .small-title {
        margin-bottom: 20px;
      }
    }

    .medium-title {
      margin-bottom: 20px;
    }

    .signature {
      position: absolute;
      left: 0;
      bottom: 125px;
      width: 225px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .right {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      width: 100%;
      overflow-x: auto;

      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;
    }

    .valeur {
      width: calc(50% - 10px;);
      height: 260px;
      background-color: $dark;
      color: #fff;
      margin-bottom: 20px;
      padding: 30px;
      position: relative;

      @media screen and (max-width: 767px) {
        min-width: 280px;
        height: 220px;
        margin-right: 20px;
        padding: 20px;
      }

      .regular-text {
        position: absolute;
        left: 30px;
        bottom: 30px;
        width: 80%;
        opacity: 0.7;
        font-size: 14px;
        color: #fff;

        @media screen and (max-width: 767px) {
          left: 20px;
          bottom: 20px;
        }
      }
    }
  }
}

.aptitudes {
  width: 100%;
  padding: 0 12vw;
  margin-top: 8vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1050px) {
    padding: 0 20px;
  }

  .left {
    width: 30%;

    @media screen and (max-width: 1050px) {
      width: 35%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .medium-title {
      margin-bottom: 20px;
    }

    .small-title {
      margin-bottom: 20px;
    }
  }

  .right {
    width: 60%;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;
    }

    .aptitude-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding-bottom: 30px;
      padding-top: 30px;

      .small-title {
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }

      .plus-icon {
        display: inline-block;
        width: 20px;
        height: 3px;
        background-color: $dark;
        background-repeat: no-repeat;
        background-size: 1.5em 1.5em;
        user-select: none;
        border-radius: 0.75em;
        transition: .3s;
        position: relative;

        @media screen and (max-width: 767px) {
          transform: scale(0.7) translateY(5px);
        }
      }

      .plus-icon:before {
        display: inline-block;
        width: 20px;
        height: 3px;
        background-repeat: no-repeat;
        background-size: 3px 3px;
        user-select: none;
        border-radius: 0.75em;
        transition: .3s;
        position: absolute;
        content: '';
        transform-origin: 10px center;
        transform: rotate3d(0, 0, 1, 90deg);
        background-color: $dark;
      }

      &:hover, &.active {
        .plus-icon {
          &:before {
            top: 0;
            width: 20px;
            transform: rotate3d(0, 0, 0, 90deg);
          }
        }
      }
    }

    .aptitude-description {
      width: 100%;
      background-color: $dark;
      color: #fff;
      padding: 0px;
      height: 0;
      overflow: hidden;
      transition: all 0.4s ease;

      &.active {
        height: auto;
        padding: 30px;

        @media screen and (max-width: 767px) {
          padding: 20px;
        }
      }

      .regular-text {
        width: 80%;
        color: #fff;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}

.references-wrap {
  width: 100%;
  padding: 0 12vw;
  margin-top: 8vw;

  @media screen and (max-width: 1050px) {
    padding: 0 20px;
  }

  .references {
    width: 100%;
    background-color: $light;
    position: relative;
    padding: 8vw 12vw;

    @media screen and (max-width: 1050px) {
      padding: 60px;
    }

    @media screen and (max-width: 767px) {
      padding: 20px;
    }

    .quote {
      position: absolute;
      left: 0;
      top: -52px;
      width: 120px;

      @media screen and (max-width: 1050px) {
        left: 20px;
        top: -22px;
        width: 80px;
      }

      @media screen and (max-width: 1050px) {
        width: 70px;
      }
    }

    .arrow-left {
      width: 16px;
      position: absolute;
      right: 100px;
      bottom: 50px;
      cursor: pointer;

      @media screen and (max-width: 767px) {
        right: 60px;
        bottom: 20px;
      }
    }

    .arrow-right {
      width: 16px;
      position: absolute;
      right: 50px;
      bottom: 50px;
      cursor: pointer;

      @media screen and (max-width: 767px) {
        right: 20px;
        bottom: 20px;
      }
    }

    .regular-text {
      font-size: 22px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }

    .temoignage {
      width: 100%;
      height: auto;

      @media screen and (max-width: 1050px) {
        position: relative;
        top: 25px;
      }
    }

    .auteur {
      display: flex;
      align-items: center;
      margin-top: 2vw;
      margin-bottom: 2vw;

      @media screen and (max-width: 767px) {
        margin-bottom: 45px;
      }

      .regular-text {
        font-size: 16px;
        font-weight: 500;
        span {
          font-weight: 300;
        }
      }

      img {
        width: 80px;
        margin-right: 20px;
      }
    }
  }
}

.contact-wrap {
  width: 100%;
  padding: 8vw 12vw 2vw 12vw;
  position: relative;
  margin-top: 4vw;

  @media screen and (max-width: 1050px) {
    padding-left: 75px;
    padding-right: 20px;
  }

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    margin-top: 45px;
  }

  .contact {
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    .left {
      width: 50%;
      position: relative;
      z-index: 100;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .right {
      width: 50%;
      padding-left: 8vw;

      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 20px 0 0 0;
        text-align: center;
      }

      .medium-title {
        margin-bottom: 15px;
      }

      .small-title {
        width: 70%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .links {
        display: flex;
        margin: 60px 0 30px 0;

        @media screen and (max-width: 767px) {
          width: 100%;
          margin: 45px auto;
          text-align: center;
          justify-content: center;

          a {
            display: inline-block;
          }
        }

        img {
          width: 30px;
          margin-right: 15px;
        }
      }
    }
  }

  .texture {
    position: absolute;
    left: 0;
    top: 0;
    width: 300px;
    z-index: 10;
  }
}

.main-footer {
  width: 100%;
  padding-left: 12vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1050px) {
    padding-left: 20px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px;
    text-align: center;

    p {
      width: 100%;
      text-align: center;
    }

    .texture {
      display: none;
    }
  }

  img {
    width: 300px;
  }
}

</style>
