const trans = {
  en: {
    // Nav
    'Expériences': 'Experience',
    'Valeurs': 'Values',
    'Aptitudes': 'Aptitudes',
    'Références': 'References',
    'Contact': 'Contact',
    'Planifions un appel': 'Let’s connect over the phone',

    // HERO
    'Prêt à partager ma vision stratégique, insuffler ma passion pour l’innovation.': 'Ready to share my strategic vision and put my passion for innovation to work.',
    'À l’attention de :': 'For the attention of:',

    'Cofondateur': 'Co-founder',
    'Élaborer les stratégies de monétisation de la première plateforme en ligne entièrement consacrée au mentorat dans le domaine des affaires.': 'Develop monetization strategies for the first online platform entirely dedicated to mentoring in the business sector. Define key performance indicators and manage strategic alliances.',
    'Partenaire': 'Partner',
    'Conseiller la présidence pour les investissements auprès des entreprises en démarrage œuvrant dans les domaines des médias, du marketing et du commerce électronique.': 'Advise the presidency on investments relating to start-ups in the media, marketing, and e-commerce sectors.',
    'Conseiller la présidence dans l’orientation stratégique, le développement commercial et le financement de l’entreprise.': 'Advise the presidency on the company’s strategic direction, commercial development, and financing.',
    'Co-Fondateur & VP Commercial': 'Co-founder &amp; VP of Sales',
    'Élaborer des stratégies de monétisation et d’optimisation des revenus des jeux en collaboration avec les équipes de production.': 'Develop monetization and optimization revenue strategies and define key performance indicators.',
    'Directeur de la transformation numérique': 'Chief of Digital Transformation',
    'Élaborer et mettre en place un écosystème capable de répondre aux objectifs d’augmentation de 45 % du recrutement d’artistes et de 40 % de nouveaux numéros originaux.': 'Develop and put in place an ecosystem capable of responding to objectives to increase artist recruitment by 45% and new original numbers by 40%. Establish goals and supervise their achievement within HR and marketing teams. Act as a digital resource for the casting service.',
    'Directeur marketing et commercial': 'Digital Marketing and E-commerce Manager',
    'Accompagner l’équipe de direction quant aux occasions de transformation numérique qui répondent aux objectifs de l’entreprise.': 'Guide the management team with regard to digital transformation opportunities that correspond to company objectives.',
    'Consultant : Stratège marketing et ecommerce': 'Consultant: Marketing and E-commerce Strategist',
    'Développer la stratégie et les modèles d’affaires du commerce en ligne pour les bannières La Vie en Rose et Bikini Village.': 'Develop the strategy and business model for La Vie en Rose and Bikini Village banners’ e-commerce. Define the business process, structure, and organizational needs.',
    'Directeur de produits interactifs et numériques': 'Director of Interactive and Digital Products',
    'Définir les stratégies de développement de produits, de services et de marques du Groupe TVA.': 'Define strategies for product development, services, and brands for Groupe TVA.',

    // Experiences
    'Un acteur de premier plan dans l’évolution du paysage numérique québécois de la dernière décennie.': 'A key player in Québec’s digital evolution over the past decade.',

    'Mon expérience m’a permis de développer, de mettre en marché et de faire rayonner plusieurs produits numériques novateurs et pertinents. Ayant orchestré d’importants virages visant à diversifier les revenus de Juste pour rire, du Cirque du Soleil, de Québecor et d’Alice et Smith Studios, je voue une réelle passion pour le marketing numérique et le commerce électronique.': 'My experience has allowed me to develop, deploy, and promote several innovative and relevant digital products. I have overseen many important shifts for well-established companies such as Just for Laughs, Cirque du Soleil, Québecor, and Alice & Smith Studios, and I have a real passion for digital marketing and e-commerce.',

    // Values
    "Pendant que certains figent devant l’inconnu, j'y saute à pieds joints.": 'While many people hesitate when faced with the unknown, I jump right in.',

    'Imputabilité': 'Accountability',
    'Les grandes décisions s’accompagnent de grandes responsabilités. Je me fais un point d’honneur de les respecter.': 'Great decisions come with great responsibilities, and I am committed to upholding said responsibilities.',

    'Créativité': 'Creativity',
    'Les contraintes d’une entreprise font émerger des possibilités dont j’éprouve un grand plaisir à activer.': 'A company’s limitations leave room for possibilities, and my passion lies in identifying and exploiting these possibilities.',

    'Curiosité': 'Curiosity',
    'La croissance cesse au moment où disparaît notre curiosité et c’est pourquoi je redouble d’efforts pour la stimuler.': 'Growth ceases to happen the moment we let our curiosity fizzle out, which is why I make a point of constantly keeping mine going.',

    'Famille': 'Family',
    'La famille est mon vecteur d’humanité, ce qui me permet de relativiser, de connecter et de réévaluer les priorités.': 'Family is my anchor—it allows me to connect, stay balanced, and re-evaluate my priorities.',

    // Aptitudes
    'Je m’efforce à bâtir sur mes forces pour collaborer et accroître celles des autres.': 'I build on my strengths in order to elevate the strengths of others.',
    'Engagé et intègre, mes aptitudes me permettent de générer d’excellentes performances en édifiant mes relations.': 'Committed and honest, my aptitudes allow me to generate excellent performances, all while building strong relationships.',

    'Stratégie numérique': 'Digital strategy',
    'Capacité d’apporter une vision stratégique en menant une réflexion approfondie et créative.': 'Ability to provide a strategic vision through a deep and creative reflection.',

    'Veille technologique': 'On top of tech trends',
    'Avant-gardiste, multidisciplinaire et à l’affût des grands courants locaux, nationaux et mondiaux.': 'Forward-thinking, multidisciplinary, and abreast of local, national, and international trends.',

    'Ressources humaines': 'Human resources',
    'Excellent vulgarisateur ayant une grande capacité à motiver et à mobiliser le personnel.': 'Excellent communicator with a great capacity to motivate and mobilize personnel.',

    'Optimisation de processus': 'Process optimization',
    'Méthodique, ingénieux et possédant de fortes capacités organisationnelles.': 'Methodical, innovative, and highly organized.',
    'Planification': 'Planning',

    'Excelle sous la pression avec un grand sens de l’imputabilité et de l’adaptabilité.': 'Excels under pressure, with a great understanding of accountability and adaptability.',

    // Contact
    'Parlons-nous': 'Let’s talk',
    'Je suis disponible pour répondre à vos questions.': 'I’m available to answer your questions.',

    // Footer
    'Tous droits réservés': 'All rights reserved'
  }
}

export default trans
