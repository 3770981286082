<template>
  <header class="navbar">
  	<div class="left">
  		<a href="#hero" v-scroll-to:-100><img src="@/assets/img/logo.png" class="logo" alt="Victor Duro"></a>
  		<nav class="main-nav">
  			<a href="#experiences" v-scroll-to:-100>{{ $t("Expériences") }}</a>
  			<a href="#valeurs" v-scroll-to:-150>{{ $t("Valeurs") }}</a>
  			<a href="#aptitudes" v-scroll-to:-150>{{ $t("Aptitudes") }}</a>
  			<a href="#references" v-scroll-to:-180>{{ $t("Références") }}</a>
  			<a href="#contact" v-scroll-to:-80>{{ $t("Contact") }}</a>
  		</nav>
  	</div>
  	<div class="right">
  		<a v-if="currentLang === 'fr'" href="/en" class="lang">EN</a>
      <a v-else href="/" class="lang">FR</a>
  		<a href="#" class="cta" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/vic-duro/15min'});return false;">{{ $t("Planifions un appel") }}</a>
  	</div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    currentLang() {
      return this.$i18n.locale
    },
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  },
  methods: {
    ...mapActions('app', ['setPanelIsOpen'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/style.scss';

.navbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 25px 4vw;
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9998;

  @media screen and (max-width: 1050px) {
    padding: 20px 20px;
  }

	.left {
		display: flex;
		align-items: center;

		.logo {
			width: 60px;
		}

		.main-nav {
			display: flex;
			align-items: center;

			a {
				font-family: $font;
				color: $dark;
				margin-left: 4vw;
				transition: all 0.2s ease;
				font-weight: 500;
				text-transform: uppercase;
				font-size: 14px;

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}

	.right {
		.lang {
			font-family: $font;
			font-weight: 500;
			font-size: 14px;
			text-transform: uppercase;
			color: $dark;
			margin-right: 2vw;
			transition: all 0.2s ease;
		}
	}

	@media screen and (max-width: 1050px) {
		.left {
			.main-nav {
				display: none;
			}
		}
	}

	@media screen and (max-width: 767px) {
    position: relative;
    .left {
      .logo {
        width: 55px;
      }
    }

    .right .lang {
      margin-right: 20px;
    }
  }
}

</style>
